const productCodes = {
    solaire: 'solaire',
    hd: 'saatva-hd',
    youth: 'saatva-youth',
    crib: 'crib-mattress',
    latexHybrid: 'saatva-latex-hybrid',
    classic: 'saatva-classic',
    loom: 'loom-and-leaf',
    zenhaven: 'zenhaven',
    memoryFoamHybrid: 'memory-foam-hybrid',
    saatvaRX: 'saatva-rx',
    dogBed: 'dog-bed',
    bunkAndTrundle: 'bunk-trundle-youth',
    contour5: 'saatva-contour5',

    adjustableBase: 'adjustable-base',
    adjustableBasePlus: 'adjustable-base-plus',
    foundation: 'foundation',
    frameSolaire: 'adjustable-frame',

    amalfi: 'amalfi',
    amalfiPlatformBed: 'amalfi-platform-bed',
    amalfiWithStorage: 'amalfi-with-storage',
    marbella: 'marbella',
    marseille: 'marseille',
    minori: 'minori',
    minoriWithStorage: 'minori-with-storage',
    santorini: 'santorini',
    santoriniPlatformBed: 'santorini-platform-bed',
    porto: 'porto',
    copenhagen: 'copenhagen',
    valencia: 'valencia',
    sydney: 'sydney',
    siena: 'siena-leather',
    lucerne: 'lucerne',
    halle: 'halle',
    rhodes: 'rhodes',
    halleWithStorage: 'halle-with-storage',
    halleStoragePlatformBed: 'halle-storage-platform-bed',
    cassis: 'cassis',
    cassisWithStorage: 'cassis-with-storage',
    milan: 'milan',
    calais: 'calais',
    lyon: 'lyon',
    lyonWithStorage: 'lyon-with-storage',
    merano: 'merano',
    crete: 'crete',
    adraLeather: 'adra-leather',
    cologneWood: 'cologne-wood',
    corsicaWood: 'corsica-wood',
    materaWood: 'matera-wood',

    scentedCandles: 'scented-candles',
    scentedVotives: 'scented-votives',

    sheets: 'organic-cotton-sheets',
    sheetsSolaire: 'organic-cotton-sheets-solaire',
    percaleSheets: 'percale-sheet-set',
    linenSheets: 'linen-sheet-set',
    cribSheets: 'crib-sheets',
    embroideredSateenSheet: 'embroidered-hotel-style-sheets',
    bandedPercaleSheetSet: 'banded-percale-sheet-set',
    waffleKnitDuvetCoverSet: 'waffle-knit-duvet-cover-set',
    flannelSheetSet: 'flannel-sheet-set',
    flannelPillowcases: 'flannel-pillowcases',
    signatureSheets: 'sateen-cotton-sheet-set',
    sateenCottonPillowcases: 'sateen-cotton-pillowcases',
    essentialSheetSet: 'essential-sheet-set',

    percaleDuvet: 'percale-duvet-cover-set',
    percaleShamPair: 'percale-shams',
    organicSateenDuvet: 'organic-sateen-duvet-cover-set',
    organicSateenShamPair: 'organic-sateen-shams',
    embroideredSateenDuvet: 'embroidered-sateen-duvet-set',
    embroideredSateenShams: 'embroidered-sateen-shams',
    embroideredSateenPillowCasePair: 'embroidered-pillowcases',
    linenDuvetCoverSet: 'linen-duvet-cover-set',
    linenShams: 'linen-shams',
    bandedPercaleDuvetSet: 'banded-percale-duvet-set',
    essentialDuvetCoverSet: 'essential-duvet-cover-set',
    signatureDuvetCoverSet: 'signature-duvet-cover-set',
    bandedPercaleShams: 'banded-percale-shams',
    bandedPercalePillowcasePair: 'banded-percale-pillowcases',
    organicCottonChannelQuilt: 'organic-cotton-channel-quilt',
    organicCottonChannelQuiltShams: 'organic-cotton-channel-quilt-shams',
    organicVelvetQuilt: 'organic-velvet-quilt',
    organicVelvetDiamondQuiltShams: 'organic-velvet-diamond-quilt-shams',
    aeroQuilt: 'aero-quilt',
    diamondKnitBlanket: 'diamond-knit-blanket',
    herringboneKnitBlanket: 'herringbone-knit-blanket',
    waffleKnitBlanket: 'waffle-knit-blanket',
    sweaterKnitBlanket: 'sweater-knit-blanket',
    velvetPickStitchQuilt: 'velvet-pick-stitch-quilt',

    downAlternativeComforter: 'down-alternative-comforter',
    lightweightAlternativeComforter: 'lightweight-down-alternative-comforter',
    heavyweightComforter: 'heavyweight-comforter',

    pillowcases: 'organic-cotton-pillowcase-pair',
    sateenPillowcase: 'organic-cotton-pillowcases',
    percalePillowcase: 'percale-pillowcases',
    linenPillowcase: 'linen-pillowcases',
    essentialPillowcases: 'essential-pillowcases',

    pillow: 'the-saatva-pillow',
    downAlternativePillow: 'down-alternative-pillow',
    foamPillow: 'memory-foam-pillow',
    cloudFoamPillow: 'cloud-memory-foam-pillow',
    organicQuiltedPillow: 'organic-quilted-pillow',

    pad: 'organic-mattress-pad',
    naturalRugPad: 'natural-rug-pad',
    weightedBlanket: 'weighted-blanket',
    mattressProtector: 'waterproof-mattress-protector',
    naturalLatexMattressTopper: 'latex-mattress-topper',
    graphiteMemoryFoamTopper: 'graphite-memory-foam-mattress-topper',
    highDensityFoamTopper: 'high-density-foam-mattress-topper',
    microcoilMattressTopper: 'microcoil-mattress-topper',
    featherbedMattressTopper: 'featherbed-mattress-topper',
    organicQuiltedMattressTopper: 'organic-quilted-mattress-topper',

    silkEyeMask: 'silk-eye-mask',
    weightedSilkEyeMask: 'weighted-silk-eye-mask',

    rhoneStorageBench: 'rhone-storage-bench',
    brienneChannelOttoman: 'brienne-channel-ottoman',
    constanceTuftedOttoman: 'constance-tufted-ottoman',
    arnoCushionedBench: 'arno-cushioned-bench',
    lenaCushionedBench: 'lena-cushioned-bench',
    anaisChair: 'anais-chair',
    comoSwivelChair: 'como-swivel-chair',
    loganChair: 'logan-chair',
    lunaSwivelChair: 'luna-swivel-chair',

    kananRug: 'kanan-bedroom-rug',
    ternaRug: 'terna-bedroom-rug',
    adamasRug: 'adamas-bedroom-rug',
    cirrusRug: 'cirrus-bedroom-rug',
    myraRug: 'myra-bedroom-rug',
    naviRug: 'navi-bedroom-rug',

    waffleTowels: 'waffle-towels',
    plushTowels: 'plush-towels',

    classicStarterBundle: 'classic-starter-bundle',
    sateenSheetsLatexPillow: 'sateen-sheets-latex-pillow',
    sateenSheetsLatexPillowPad: 'sateen-sheets-latex-pillow-pad',
    sateenSheetsMemoryFoamPillow: 'sateen-sheets-memory-foam-pillow'
}

export default productCodes
