import { formatCurrency } from '@saatva-bits/pattern-library.utils.price-format'

/**
 * @param {number} discount
 * @param {'range' | 'exact'} message
 * @returns
 */
export const getBadgeText = (discount, message = 'range') => {
    const upToMessage = 'Save up to'
    const exactMessage = 'Save'
    const formattedPrice = formatCurrency(discount, 0)

    if (!(discount > 0)) {
        return null
    }

    switch (message) {
        case 'exact': {
            return `${exactMessage} ${formattedPrice}`
        }
        case 'range':
        default:
            return `${upToMessage} ${formattedPrice}`
    }
}
