const affirmUIRefresh = () => {
    if (window && Object.prototype.hasOwnProperty.call(window, 'affirm')) {
        affirm.ui.ready(() => {
            affirm.ui.refresh()
        })
    }
}

export const affirmRefresh = (delay = null) => {
    delay && typeof delay === 'number'
        ? setTimeout(() => affirmUIRefresh(), delay)
        : affirmUIRefresh()
}
